/* Scoreboard */
#scoreboard {
  display: flex;
  justify-content: space-around;
  margin-top: 2rem;
  border-radius: 5px;
  border: 1px solid var(--secondary-color);
  background: var(--light-color);
}

#scoreboard div {
  flex: 1;
  text-align: center;
  padding: 1rem 1rem 0.8rem;
  color: var(--dark-color);
  text-transform: uppercase;
  font-weight: 400;
}

#scoreboard div p:nth-child(2) {
  font-size: 3rem;
  color: var(--secondary-color);
  margin-top: 0.5rem;
  font-weight: 700;
}

/* Prompt */
#prompt {
  margin-top: 3rem;
  text-align: center;
}

#prompt button:first-of-type {
  margin-right: 1rem;
}

#reset-score-wrapper {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  margin-bottom: 2rem;
}

#reset-score,
#game-over-reset-score {
  padding: 0.5rem 1rem;
}

#reset-score:hover,
#game-over-reset-score:hover {
  background: var(--secondary-color);
}

/* Item */
#item {
  text-align: center;
  font-weight: 700;
  color: var(--light-color);
  font-size: 10rem;
  width: 50%;
  margin: 3rem auto 0;
  background: linear-gradient(
    to bottom right,
    var(--primary-color),
    var(--light-color),
    var(--secondary-color)
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
  position: relative;
  box-shadow: 0 0 4px white;
}

/* Game Over */
#game-over {
  color: var(--secondary-color);
  font-size: 3rem;
  margin-bottom: 2rem;
  margin-top: 4rem;
  text-transform: uppercase;
}
