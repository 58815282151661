@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

* {
  margin: 0;
  padding: 0;
  border: none;
  outline: none;
  box-sizing: border-box;
  user-select: none;
}

:root {
  --primary-color: #1abc9c;
  --secondary-color: #e67e22;
  --light-color: #ecf0f1;
  --dark-color: #34495e;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

body {
  background-color: var(--dark-color);
  font-family: 'Roboto', sans-serif;
  color: var(--light-color);
}

header {
  padding: 0.5rem 0;
  text-align: center;
  background: var(--light-color);
  color: var(--dark-color);
}

header p {
  padding-bottom: 0.8rem;
}

footer {
  background-color: var(--primary-color);
  padding: 1rem;
  text-align: center;
  margin-top: auto;
  display: block;
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 0;
}

h1 {
  padding-bottom: 0.5rem;
}

h3 {
  text-transform: uppercase;
  font-weight: 400;
}

a {
  color: var(--dark-color);
  text-decoration: none;
}

a:hover {
  color: var(--light-color);
  cursor: pointer;
}

button {
  background: var(--light-color);
  color: var(--dark-color);
  border-radius: 5px;
  padding: 1rem 2rem;
  text-transform: uppercase;
  margin-top: 1rem;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  user-select: none;
}

button:hover {
  background: var(--primary-color);
  color: var(--light-color);
  cursor: pointer;
}

strong {
  color: var(--secondary-color);
  display: block;
  font-size: 2.5rem;
  padding: 0.5rem 0;
  font-style: italic;
}

strong span {
  color: var(--primary-color);
}

.container {
  padding-left: 10%;
  padding-right: 10%;
}

.d-none {
  display: none;
}

/* Game Intro */
#game-intro {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: center;
}

#game-intro h1 {
  margin-bottom: 1rem;
  text-transform: capitalize;
}

#game-intro p {
  padding: 2rem;
}

#game-intro button {
  margin-top: 2rem;
}

/* Media Queries */
@media only screen and (min-width: 768px) {
  .container {
    padding-left: 25%;
    padding-right: 25%;
  }
}
